// core version + pagination modules:
import Swiper from '../node_modules/swiper/swiper-bundle.esm.browser.min.js';
import css from "../node_modules/swiper/swiper-bundle.css";
import $ from "../node_modules/jquery/dist/jquery.js";

const paginationEl = document.querySelector('.home-hero .swiper-pagination');
const vericalTabsPag = document.querySelector('.vertical-tabs .right-container .swiper-pagination');
const logitButton = document.querySelector('.form-login-button');
const $logOut = $('.logout-button');
const $loginPopup = $('.login-button');
const $loginForm = $('.login-popup');
const $closeBtn = $('.close-button');
let $menuItem = $('.main-menu .session');
let $footerMenuItem = $('.footer-menu .session');
let slideTitles = document.querySelectorAll('.vertical-tabs .tab-title');

let loggedIn = localStorage.getItem('loggedIn');

if(loggedIn) {
  $loginPopup.hide();
  $('.logout-button').show();
  $menuItem.show();
  $footerMenuItem.show();
}
else {
  $loginPopup.show();
  $('.logout-button').hide();
  $menuItem.hide();
  $footerMenuItem.hide();
}

// Home Hero Swiper
const homeHero = new Swiper('.home-hero .swiper', {
  slidesPerView: 1,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  simulateTouch: false,
  pagination: {
    el: paginationEl,
    type: 'bullets',
    clickable: true,
  },
});

// Vertical Tabs Swiper
const verticalTabs = new Swiper('.vertical-tabs .swiper-container', {
  speed: 0,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  pagination: {
    el: vericalTabsPag,
    clickable: true,
    bulletClass: 'swiper-pagination-customs',
    bulletActiveClass: 'swiper-pagination-customs-active',
    renderBullet: function (index, className) {
      // side navigation
      let slideTitle = slideTitles[index].textContent;

      return (
        '<li class="' + className + '"><button class="side-nav-link">' +
        slideTitle +
        '</button></li>'
      );
    }
  },
});

// Hamburger menu
const hamburger = document.querySelector('.hamburger');
const mainMenu = document.querySelector('.nav-wrapper');
const body = document.querySelector('body');
const menuOverlay = document.querySelector('.menu-overlay');
let navItems = mainMenu.querySelectorAll('a');

function fadeToggle() {
  menuOverlay.style.transition = 'opacity .2s';
  const { opacity } = menuOverlay.ownerDocument.defaultView.getComputedStyle(menuOverlay, null);

  if (opacity === '1') {
    menuOverlay.style.opacity = '0';
  }
  else {
    menuOverlay.style.opacity = '1';
  }
}

hamburger.addEventListener('click', e => {
  hamburger.classList.toggle('is-active');
  mainMenu.classList.toggle('is-active');
  body.classList.toggle('overlayed');
  fadeToggle();
});

for (let i = 0; i < navItems.length; i++) {
  navItems[i].addEventListener('click', () => {
    hamburger.classList.remove('is-active');
    mainMenu.classList.remove('is-active');
    body.classList.remove('overlayed');
    fadeToggle();
  });
}

$loginPopup.on('click', () => {
  $loginForm.css('display', 'flex').hide().fadeIn(200);
  $('body').addClass('overlayed');
});

$closeBtn.on('click', () => {
  $loginForm.fadeOut(200);
  $('body').removeClass('overlayed');
});

$logOut.on('click', () => {
  localStorage.removeItem('loggedIn');
  location.reload();
});

// Login Event
logitButton.addEventListener('click', e => {
  e.preventDefault();

  let email = document.getElementById('email').value;
  let password = document.getElementById('password').value;

  if (email === 'demo@demo.com' && password === 'demo') {
    window.location.replace('/index-1.html');
    localStorage.setItem('loggedIn', true);
  } else {
    alert('Invalid information');
    return;
  }
});


// Lang Switcher
const html = document.querySelector('html');
const currentLang = document.querySelector('.current-lang');
const langList = document.querySelector('.lang-list');
let langItems = langList.querySelectorAll('li');

currentLang.addEventListener('click', () => {
  currentLang.classList.toggle('active');
  langList.classList.toggle('show');
});

html.addEventListener('click', e => {
  if (e.target !== langList && e.target !== currentLang) {
    currentLang.classList.remove('active');
    langList.classList.remove('show');
  }
});

for (let i = 0; i < langItems.length; i++) {
  langItems[i].addEventListener('click', () => {
    Array.from(langItems).map(x => x.classList.remove('selected'));
    langItems[i].classList.add('selected');
    currentLang.textContent = langItems[i].textContent;
    html.setAttribute('lang', langItems[i].dataset.lang);
  });
}
